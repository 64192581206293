#time2go {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
    padding-top: 10px;
    margin-top: 10px;
    position: relative;

    .t2-separator {
        height: 2px;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 18px;
        right: 18px;
    }
    .t2-counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        //padding: 0 12px;
        //max-width: 160px;

        .short {
            display: none;
        }

        .long {
            text-transform: capitalize;
            font-size: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &:last-child {
            margin-right: 0;

            .t2-digit:after {
                content: '';
            }
        }
        // &:first-child {

        //     .t2-digit:after {
        //         content: '';
        //     }
        // }
    }
    .t2-part {
        line-height: 1;
        //background-color: $text-color;
        //color: $text-reverse;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .t2-digit {
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1em;
        width: 100%;
        padding-bottom: 3px;
        margin-bottom: 10px;
        position: relative;

        span {
            border-bottom: 2px solid #fff;
        }

        &:after {
            content: ':';
            position: absolute;
            right: -5px;
        }
    }
}

@media (min-width: 375px) {
    #time2go {
        .t2-counter {
            //padding: 0 15px;

            .long {
                font-size: 12px;
            }
        }

        .t2-digit {
            font-size: 40px;
            width: 80px;

            &:after {
            }
        }
    }
}

@media (min-width: 540px) {
    #time2go {
        //padding: 25px 5vw;
        .t2-counter {
            //padding: 0 25px;

            .long {
                font-size: 14px;
            }
        }

        .t2-separator {
            left: 21px;
            right: 21px;
        }

        .t2-digit {
            font-size: 50px;
            width: 100px;

            &:after {
            }
        }
    }
}

@media (min-width: 768px) {
    #time2go {
        //padding: 50px 10vw;

        .t2-separator {
            left: 26px;
            right: 26px;
        }

        .t2-counter {
            //padding: 0 25px;

            .long {
                font-size: 16px;
            }
        }

        .t2-digit {
            font-size: 60px;
            width: 120px;

            &:after {
            }
        }
    }
}

@media (min-width: 992px) {
    #time2go {
        //padding: 25px 0;

        .t2-separator {
            left: 32px;
            right: 32px;
        }

        .t2-counter {
            //padding: 0 35px;
            width: 100%;

            .long {
                font-size: 18px;
            }
        }

        .t2-digit {
            font-size: 100px;
            width: 180px;

            &:after {
                right: -20px;
            }
        }
    }
}

@media (min-width: 1200px) {
    #time2go {
        .t2-counter {
            //  width: 190px;
        }
    }
}
