$bottom-logo-width: 100px;
$bottom-logo-width-500: 120px;
$bottom-logo-width-768: 150px;
$bottom-logo-width-992: 180px;
$bottom-logo-width-max: 210px;

footer {
    position: relative;

    &.footer-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            max-width: 230px;
            margin: 15px auto;
        }
    }
}
